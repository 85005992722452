import React, { useEffect, useState } from "react";
import CreationsCard from "../components/CreationsCard";
import {
  Input,
  Button,
  Space,
  Drawer,
  Form,
  Select,
  DatePicker,
  Table,
} from "antd";
import {
  FilterOutlined,
  DeleteFilled,
  CheckOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { LuTable2 } from "react-icons/lu";
import {
  MdOutlineBurstMode,
  MdSocialDistance,
} from "react-icons/md";
import locale from "antd/es/date-picker/locale/tr_TR";
import { FcSalesPerformance } from "react-icons/fc";
import { FaElementor, FaWarehouse } from "react-icons/fa";
import { BiSupport } from "react-icons/bi";
import ProfileMenu from "../components/ProfileMenu";
//import "moment/locale/tr";
const { RangePicker } = DatePicker;

const MyCreations = () => {
  const data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 1, 1, 1, 1, 1, 1, 1, 1];

  const [form] = Form.useForm();

  const [open, setOpen] = useState(false);
  const [defaultPage, setDefaultPage] = useState(true);

  const handleSearch = (value) => {
    console.log("Aranan değer:", value);
  };

  const handleFilter = () => {
    console.log("Filtreleme işlemi");
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onClickDeleteIcon = () => {
    form.resetFields();
    form.submit();
  };

  const onClickFilterBtn = () => {
    form.submit();
  };

  const favoriteOptions = [
    { value: "0", label: "Evet" },
    { value: "1", label: "Hayır" },
  ];

  const aiOptions = [
    { value: "0", label: "Pose Craft" },
    { value: "1", label: "Pix2Pix" },
  ];

  const onChange = (value, dateString) => {
    console.log(dateString);
  };

  const onClickFirst = () => {
    setDefaultPage(true);
  };

  const onClickSecond = () => {
    setDefaultPage(false);
  };

  const columns = [
    {
      title: "AI Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Created Date",
      dataIndex: "created_date",
      key: "created_date",
    },
    {
      title: "Action",
      width: "7%",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <span>
            <EditOutlined
              style={{ color: "green" }}
              onClick={() => [setRowData(record), setOpenDrawer(true)]}
            />
          </span>
          <span>
            <DeleteOutlined
              style={{ color: "red" }}
              onClick={() => onClickDelete(record)}
            />
          </span>
        </Space>
      ),
    },
  ];

  const [openDrawer, setOpenDrawer] = useState(false);
  const [rowData, setRowData] = useState();
  const [dataTable, setDataTable] = useState([]);

  const onCloseDrawer = () => setOpenDrawer(false);

  const onClickDelete = (record) => {
    const a = JSON.parse(localStorage.getItem("data_table"));
    const valueofarray = (element) => element.key === record.key;
    const index = a.findIndex(valueofarray);
    console.log(index);
    if (index === 0) a.splice(index, index + 1);
    else a.splice(index, 1);
    localStorage.setItem("data_table", JSON.stringify(a));
    setDataTable(JSON.parse(localStorage.getItem("data_table")));
  };

  const handleSubmit = (values) => {
    const a = JSON.parse(localStorage.getItem("data_table"));
    const b = a.find((item) => item.name === rowData.name);
    b.name = values.name;
    localStorage.setItem("data_table", JSON.stringify(a));
    setDataTable(JSON.parse(localStorage.getItem("data_table")));
    setOpenDrawer(false);
  };

  useEffect(() => {
    const a = localStorage.getItem("data_table");
    if (a === null) {
      localStorage.setItem(
        "data_table",
        JSON.stringify([
          {
            key: "1",
            name: "My First AI",
            created_date: "01/03/2023",
          },
          {
            key: "2",
            name: "Example AI",
            created_date: "04/23/2023",
          },
          {
            key: "3",
            name: "Last AI",
            created_date: "03/11/2023",
          },
          {
            key: "4",
            name: "Selim AI",
            created_date: "30/05/2021",
          },
          {
            key: "5",
            name: "Ersen AI",
            created_date: "12/12/2023",
          },
          {
            key: "6",
            name: "Serim AI",
            created_date: "28/07/2023",
          },
          {
            key: "7",
            name: "Pose Craft AI",
            created_date: "17/01/2022",
          },
          {
            key: "8",
            name: "Shape Shifters AI",
            created_date: "19/10/2020",
          },
        ])
      );
    } else {
      setDataTable(JSON.parse(localStorage.getItem("data_table")));
    }
  }, []);

  return (
    <>
      <div style={{ padding: "5% 10%" }} className="background-div">
        <div className="homeScreenMenu">
          <div className="containerMenu">
            <button className="buttonMenu">AI</button>
            <div className="menu" style={{ zIndex: 1 }}>
              <a href="/my-creations">
                <LuTable2 color="white" className="menuIcon" /> My Creations
              </a>
              <a href="/modals">
                <MdOutlineBurstMode color="white" className="menuIcon" /> Modals
              </a>
              <a href="/podium">
                <FcSalesPerformance color="white" className="menuIcon" /> Podium
              </a>
              <a href="/products">
                <FaElementor color="white" className="menuIcon" /> Portfolio
              </a>
              <a href="/company">
                <FaWarehouse color="white" className="menuIcon" /> Company
              </a>
              <a href="/support">
                <BiSupport color="white" className="menuIcon" /> Support
              </a>
              <a href="/socials">
                <MdSocialDistance color="white" className="menuIcon" /> Socials
              </a>
            </div>
          </div>
        </div>
        <ProfileMenu />
        <div
          style={{
            margin: "20px",
            position: "absolute",
            left: "43%",
            top: "10px",
          }}
        >
          <Button
            type="primary"
            style={{
              borderBottom: "1px solid white",
              backgroundColor: "inherit",
              color: "white",
              marginRight: "30px",
              borderBottom: '3px solid red', 
              borderImage: 'linear-gradient(to top, #007bff, transparent) 1',
              borderImageSlice: '1',
            }}
            onClick={onClickFirst}
          >
            My Creations
          </Button>
          <Button
            type="primary"
            style={{
              // borderBottom: "1px solid white",
              backgroundColor: "inherit",
              color: "white",
              borderBottom: '3px solid red', 
              borderImage: 'linear-gradient(to top, #007bff, transparent) 1',
              borderImageSlice: '1',
            }}
            onClick={onClickSecond}
          >
            My Algorithms
          </Button>
    </div>
        {/* {defaultPage ? (
          <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
            width: "90%",
            marginLeft: "2%",
          }}
        >
          <Input
            placeholder="Search with ai name..."
            onSearch={handleSearch}
            style={{ borderRadius: "10px 0 0 10px", padding: "8px 20px" }}
          />
          <Space>
            <Button
              style={{ borderRadius: "0 10px 10px 0", backgroundColor: "gray" }}
              size="large"
              type="primary"
              icon={<FilterOutlined style={{ color: "white" }} />}
              onClick={handleFilter}
            />
            <Drawer
              title="Filtreleme"
              placement="right"
              onClose={onClose}
              open={open}
              extra={
                <Space>
                  <Button
                    onClick={onClickDeleteIcon}
                    style={{ border: "none" }}
                  >
                    <DeleteFilled style={{ color: "#D13131" }} />
                  </Button>
                  <Button
                    onClick={onClickFilterBtn}
                    style={{
                      border: "1px solid black",
                      color: "white",
                      backgroundColor: "black",
                    }}
                  >
                    Filtrele
                  </Button>
                </Space>
              }
            >
              <Form form={form} layout="vertical">
                <Form.Item
                  label="Favorite"
                  name="favorite"
                  tooltip="Favorite Condition"
                >
                  <Select options={favoriteOptions} />
                </Form.Item>
                <Form.Item label="AI" name="ai">
                  <Select options={aiOptions} />
                </Form.Item>
                <Form.Item label="Starting - Ending Date" name="date">
                  <RangePicker locale={locale} onChange={onChange} />
                </Form.Item>
              </Form>
            </Drawer>
          </Space>
        </div>
          data.map((item) => <CreationsCard key={item.id} item={item} />)
        ) : (
          <>
            {dataTable && (
              <Table
                style={{ padding: "100px" }}
                columns={columns}
                dataSource={dataTable}
              />
            )}
            <Drawer
              title={rowData?.name}
              placement="right"
              onClose={onCloseDrawer}
              open={openDrawer}
              width="30%"
            >
              <Form form={form} onFinish={handleSubmit}>
                <Form.Item label="Name" name="name">
                  <Input placeholder={rowData?.name}></Input>
                </Form.Item>
                <Form.Item label="Created Date" name="created_date">
                  <Input disabled placeholder={rowData?.created_date}></Input>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      border: "none",
                      boxShadow: "none",
                    }}
                  >
                    Edit <CheckOutlined style={{ color: "green" }} />
                  </Button>
                </Form.Item>
              </Form>
            </Drawer>
          </>
        )} */}

        {defaultPage ? (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
                width: "90%",
                marginLeft: "2%",
              }}
            >
              <Input
                placeholder="Search with ai name..."
                onSearch={handleSearch}
                style={{ borderRadius: "10px 0 0 10px", padding: "8px 20px" }}
              />
              <Space>
                <Button
                  style={{
                    borderRadius: "0 10px 10px 0",
                    backgroundColor: "gray",
                  }}
                  size="large"
                  type="primary"
                  icon={<FilterOutlined style={{ color: "white" }} />}
                  onClick={handleFilter}
                />
                <Drawer
                  title="Filtreleme"
                  placement="right"
                  onClose={onClose}
                  open={open}
                  extra={
                    <Space>
                      <Button
                        onClick={onClickDeleteIcon}
                        style={{ border: "none" }}
                      >
                        <DeleteFilled style={{ color: "#D13131" }} />
                      </Button>
                      <Button
                        onClick={onClickFilterBtn}
                        style={{
                          border: "1px solid black",
                          color: "white",
                          backgroundColor: "black",
                        }}
                      >
                        Filtrele
                      </Button>
                    </Space>
                  }
                >
                  <Form form={form} layout="vertical">
                    <Form.Item
                      label="Favorite"
                      name="favorite"
                      tooltip="Favorite Condition"
                    >
                      <Select options={favoriteOptions} />
                    </Form.Item>
                    <Form.Item label="AI" name="ai">
                      <Select options={aiOptions} />
                    </Form.Item>
                    <Form.Item label="Starting - Ending Date" name="date">
                      <RangePicker locale={locale} onChange={onChange} />
                    </Form.Item>
                  </Form>
                </Drawer>
              </Space>
            </div>
            {data.map((item) => (
              <CreationsCard key={item.id} item={item} />
            ))}
          </>
        ) : (
          <>
            {dataTable && (
              <Table
                style={{ padding: "100px" }}
                columns={columns}
                dataSource={dataTable}
              />
            )}
            <Drawer
              title={rowData?.name}
              placement="right"
              onClose={onCloseDrawer}
              open={openDrawer}
              width="30%"
            >
              <Form form={form} onFinish={handleSubmit}>
                <Form.Item label="Name" name="name">
                  <Input placeholder={rowData?.name}></Input>
                </Form.Item>
                <Form.Item label="Created Date" name="created_date">
                  <Input disabled placeholder={rowData?.created_date}></Input>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      border: "none",
                      boxShadow: "none",
                    }}
                  >
                    Edit <CheckOutlined style={{ color: "green" }} />
                  </Button>
                </Form.Item>
              </Form>
            </Drawer>
          </>
        )}
      </div>
    </>
  );
};

export default MyCreations;
