import React, { useEffect, useState } from "react";
import bgVideo2 from "../assets/bgVideo2.mp4";
import Base64Image from "../components/Base64Image";
import { post } from "../helper";
import { LuTable2 } from "react-icons/lu";
import { FcSalesPerformance } from "react-icons/fc";
import { FaElementor, FaWarehouse } from "react-icons/fa";
import { BiSupport } from "react-icons/bi";
import { MdSocialDistance, MdFolderSpecial } from "react-icons/md";
import { ImProfile } from "react-icons/im";
import { FiSettings, FiLogOut } from "react-icons/fi";
import { Input, InputNumber, Modal, Slider } from "antd";
import ScreenHome from "./Screen";
import ScreenHomeMobile from "./ScreenHomeMobile";

const Home = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [base64Image, setBase64Image] = useState("");
  const [promptValue, setPromptValue] = useState();
  const [base64Code, setBase64Code] = useState();
  const [isLoadingPose, setIsLoadingPose] = useState(false);
  const [isLoadingPix, setIsLoadingPix] = useState(false);
  const [id, setId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState();
  const [buttonText, setButtonText] = useState("1 AI");

  const onChangeUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
        const base64String = reader.result;
        setBase64Image(base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmitPose = async () => {
    setIsLoadingPose(true);
    const obj = {
      image: base64Image,
      prompt: promptValue,
      type: 0,
    };
    try {
      const result = await post("images/generate_pose/reserve", obj);
      setId(result.data.id);
      if (result?.status === 200) {
        // setView(true);
        // setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) alert("Hata meydana geldi");
      setIsLoadingPose(false);
    }
  };

  const handleSubmitPix2Pix = async () => {
    setIsLoadingPix(true);
    const obj = {
      image: base64Image,
      prompt: promptValue,
      type: 1,
    };
    try {
      const result = await post("images/generate_pose/reserve", obj);
      setId(result.data.id);
      if (result?.status === 200) {
        // setView(true);
        // setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) alert("Hata meydana geldi");
      setIsLoadingPix(false);
    }
  };

  const onChangePrompt = (e) => {
    setPromptValue(e.target.value);
  };

  const deneme = () => {
    setTimeout(async () => {
      if (id) {
        const obj = {
          id: id,
        };
        try {
          const result = await post("images/generate_pose/get_image", obj);
          if (result?.data?.image) {
            setIsLoadingPix(false);
            setIsLoadingPose(false);
            setBase64Code(result?.data?.image?.$binary);
            setId(null);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }, 50000);
  };

  const handleCancel = () => setIsModalOpen(false);

  const handleOk = () => {
    setIsModalOpen(false);
    setButtonText(title);
  };

  useEffect(() => {
    deneme();
  }, [id]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Sayfa yüklendiğinde ve ekran boyutu değiştiğinde handleResize fonksiyonunu çağırın
    window.addEventListener("resize", handleResize);
    return () => {
      // Bileşen kaldırıldığında event listener'ı temizleyin
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {windowWidth < 800 ? <ScreenHomeMobile/> : <ScreenHome/> }
    </>
  );
};

export default Home;
