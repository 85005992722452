import { Button, Card, Drawer, Form, Input, Select, Space, Table } from "antd";
import React from "react";
import { FcSalesPerformance } from "react-icons/fc";
import { FaElementor, FaWarehouse } from "react-icons/fa";
import { BiSupport } from "react-icons/bi";
import { ImProfile } from "react-icons/im";
import { FiLogOut, FiSettings } from "react-icons/fi";
import { LuTable2 } from "react-icons/lu";
import {
  MdFolderSpecial,
  MdOutlineBurstMode,
  MdSocialDistance,
} from "react-icons/md";
import {
  FilterOutlined,
  DeleteFilled,
} from "@ant-design/icons";
import PodiumCard from "../components/PodiumCard";
import ProfileMenu from "../components/ProfileMenu";


const cardData = [
  {
    name:"Selim",
    surname:"Can",
    brand:"Selim Brand",
  },
  {
    name:"Ersen",
    surname:"Taşkın",
    brand:"Ersen Taşkın Brand",
  },
  {
    name:"Serim",
    surname:"Gençaslan",
    brand:"Serim Brand",
  },
  {
    name:"Berkay",
    surname:"Karatay",
    brand:"Berkay Brand",
  },
  {
    name:"Enes",
    surname:"Doğan",
    brand:"Enes Brand",
  },
  {
    name:"Selim",
    surname:"Yıldız",
    brand:"Yıldız Brand",
  },
];

const MarketPlace = () => {

  const [form] = Form.useForm();

  const favoriteOptions = [
    { value: "0", label: "Evet" },
    { value: "1", label: "Hayır" },
  ];

  const aiOptions = [
    { value: "0", label: "Pose Craft" },
    { value: "1", label: "Pix2Pix" },
  ];

  const columns = [
    {
      title: "Trend Colors",
      dataIndex: "trendColors",
      sorter: true,
      render: (text) => (
        <a style={{ color: "black", fontWeight: 600 }}>{text}</a>
      ),
      align: "center",
    },
    {
      title: "Trend Selling Products",
      dataIndex: "trendSellingProducts",
      render: (text) => (
        <a style={{ color: "black", fontWeight: 600 }}>{text}</a>
      ),
      align: "center",
    },
    {
      title: "Most Review Accounts",
      dataIndex: "accounts",
      render: (text) => (
        <a style={{ color: "black", fontWeight: 600 }}>{text}</a>
      ),
      align: "center",
    },
    {
      title: "İşlem Hacmi",
      dataIndex: "transactionVolume",
      align: "center",
    },
    {
      title: "Type",
      dataIndex: "type",
      align: "center",
    },
  ];
  const data = [
    {
      key: "1",
      trendColors: "Red",
      trendSellingProducts: "Product 1",
      accounts: "Selim",
      transactionVolume: "2.500",
      type: "Type 1",
    },
    {
      key: "2",
      trendColors: "Green",
      trendSellingProducts: "Product 2",
      accounts: "Ersen",
      transactionVolume: "3.000",
      type: "Type 2",
    },
    {
      key: "3",
      trendColors: "Black",
      trendSellingProducts: "Product 3",
      accounts: "Serim",
      transactionVolume: "4.000",
      type: "Type 3",
    },
    {
      key: "4",
      trendColors: "Yellow",
      trendSellingProducts: "Product 4",
      accounts: "Berkay",
      transactionVolume: "6.000",
      type: "Type 4",
    },
    {
      key: "5",
      trendColors: "Yellow",
      trendSellingProducts: "Product 4",
      accounts: "Berkay",
      transactionVolume: "4.000",
      type: "Type 4",
    },
    {
      key: "6",
      trendColors: "Yellow",
      trendSellingProducts: "Product 4",
      accounts: "Berkay",
      transactionVolume: "5.300",
      type: "Type 4",
    },
    {
      key: "7",
      trendColors: "Yellow",
      trendSellingProducts: "Product 4",
      accounts: "Berkay",
      transactionVolume: "4.000",
      type: "Type 4",
    },
    {
      key: "8",
      trendColors: "Yellow",
      trendSellingProducts: "Product 4",
      accounts: "Berkay",
      transactionVolume: "4.000",
      type: "Type 4",
    },
    {
      key: "9",
      trendColors: "Yellow",
      trendSellingProducts: "Product 4",
      accounts: "Berkay",
      transactionVolume: "4.000",
      type: "Type 4",
    },
    {
      key: "10",
      trendColors: "Yellow",
      trendSellingProducts: "Product 4",
      accounts: "Berkay",
      transactionVolume: "4.000",
      type: "Type 4",
    },
    {
      key: "11",
      trendColors: "Yellow",
      trendSellingProducts: "Product 4",
      accounts: "Berkay",
      transactionVolume: "7.600",
      type: "Type 4",
    },
    {
      key: "12",
      trendColors: "Yellow",
      trendSellingProducts: "Product 4",
      accounts: "Berkay",
      transactionVolume: "4.000",
      type: "Type 4",
    },
    {
      key: "13",
      trendColors: "Yellow",
      trendSellingProducts: "Product 4",
      accounts: "Berkay",
      transactionVolume: "4.000",
      type: "Type 4",
    },
  ];
  return (
    <>
      <div
        style={{ padding: "8%", height: "140vh" }}
        className="background-div"
      >
        <div className="homeScreenMenu">
          <div className="containerMenu">
            <button className="buttonMenu">AI</button>
            <div className="menu" style={{ zIndex: 1 }}>
              <a href="/my-creations">
                <LuTable2 color="white" className="menuIcon" /> My Creations
              </a>
              <a href="/modals">
                <MdOutlineBurstMode color="white" className="menuIcon" /> Modals
              </a>
              <a href="/podium">
                <FcSalesPerformance color="white" className="menuIcon" /> Podium
              </a>
              <a href="/products">
                <FaElementor color="white" className="menuIcon" /> Portfolio
              </a>
              <a href="/company">
                <FaWarehouse color="white" className="menuIcon" /> Company
              </a>
              <a href="/support">
                <BiSupport color="white" className="menuIcon" /> Support
              </a>
              <a href="/socials">
                <MdSocialDistance color="white" className="menuIcon" /> Socials
              </a>
            </div>
          </div>
        </div>
        <ProfileMenu />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
            width: "90%",
            marginLeft: "2%",
          }}
        >
          <Input
            placeholder="Search with ai name..."
            // onSearch={handleSearch}
            style={{ borderRadius: "10px 0 0 10px", padding: "8px 20px" }}
          />
          <Space>
            <Button
              style={{
                borderRadius: "0 10px 10px 0",
                backgroundColor: "gray",
              }}
              size="large"
              type="primary"
              icon={<FilterOutlined style={{ color: "white" }} />}
              // onClick={handleFilter}
            />
            {/* <Drawer
              title="Filtreleme"
              placement="right"
              // onClose={onClose}
              open={open}
              extra={
                <Space>
                  <Button
                    // onClick={onClickDeleteIcon}
                    style={{ border: "none" }}
                  >
                    <DeleteFilled style={{ color: "#D13131" }} />
                  </Button>
                  <Button
                    // onClick={onClickFilterBtn}
                    style={{
                      border: "1px solid black",
                      color: "white",
                      backgroundColor: "black",
                    }}
                  >
                    Filtrele
                  </Button>
                </Space>
              }
            >
              <Form form={form} layout="vertical">
                <Form.Item
                  label="Favorite"
                  name="favorite"
                  tooltip="Favorite Condition"
                >
                  <Select options={favoriteOptions} />
                </Form.Item>
                <Form.Item label="AI" name="ai">
                  <Select options={aiOptions} />
                </Form.Item>
                <Form.Item label="Starting - Ending Date" name="date">
                  <RangePicker locale={locale} onChange={onChange} />
                </Form.Item>
              </Form>
            </Drawer> */}
          </Space>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "2%",
            marginBottom: "40px",
            marginTop:"30px",
            opacity: 0.9,
          }}
        >
          {cardData.map((item) => (
            <PodiumCard name={item.name} surname={item.surname} brand={item.brand}/>
          ))}
        </div>
        <Table
          style={{ opacity: 0.9, height: "50vh" }}
          columns={columns}
          dataSource={data}
          pagination={10}
        />
      </div>
    </>
  );
};

export default MarketPlace;
