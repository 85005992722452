import { loginInstance, instance } from "./Axios";

const loginPost = (endpoint, credentials = null) =>
  loginInstance.post(endpoint, credentials);

const post = (endpoint, credentials = null) => 
  instance.post(endpoint, credentials);

const put = (endpoint, credentials = null) =>
  instance.put(endpoint, credentials);

const delete_ = (endpoint, credentials = null) =>
  instance.delete(endpoint, credentials);

const get = (endpoint, credentials = null) => {
  const url = credentials ? `${endpoint}?${credentials}` : endpoint;
  return instance.get(url);
};

export { get, post, put, delete_, loginPost };
