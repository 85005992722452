import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Card, Popconfirm } from "antd";
import React, { useState } from "react";

const PodiumCard = ({ name, surname, brand }) => {
  const [showButtons, setShowButtons] = useState(false);

  const confirm = (e) => {
    console.log(e);
  };

  const cancel = (e) => {
    console.log(e);
  };

  const handlePlusClick = () => {
    setShowButtons((prev) => !prev);
  };

  return (
    <Card className="podium-card">
      <p>{name}</p>
      <p>{surname}</p>
      <p>{brand}</p>
      <Popconfirm
        // title="Delete the task"
        description="Are you sure to download this pdf?"
        onConfirm={confirm}
        onCancel={cancel}
        okText="Yes"
        cancelText="No"
      >
        <Button
          style={{ border: "none", backgroundColor: "inherit", color: "white" }}
        >
          Kontrat Bilgileri
        </Button>
      </Popconfirm>
      <p>
        <PlusCircleOutlined
          onClick={handlePlusClick}
          style={{ fontSize: "30px", marginTop: "10px" }}
        />
      </p>
      {showButtons && (
        <div style={{ position: "relative" }}>
          {/* Card içeriği */}
          <Button
            className="gradient-text"
            style={{
              position: "absolute",
              backgroundColor: "inherit",
              border: "none",
              top: -40,
              right: -25,
              width: "75px",
              textAlign: "center",
            }}
          >
            Contact
          </Button>
          <Button
            className="gradient-text"
            style={{
              position: "absolute",
              backgroundColor: "inherit",
              border: "none",
              top: -20,
              right: -25,
              width: "75px",
              textAlign: "center",
            }}
          >
            Offer
          </Button>
        </div>
        // <>
        //   {" "}
        //   <p>
        //     <Button style={{ backgroundColor: "inherit", color: "white", marginTop:"5px" }}>Contact</Button>
        //   </p>
        //   <p>
        //     <Button style={{ backgroundColor: "inherit", color: "white", marginTop:"5px" }}>Offer</Button>
        //   </p>
        // </>
      )}
    </Card>
  );
};

export default PodiumCard;
