import React from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Avatar, Card, Input } from "antd";
const { Meta } = Card;

const CreationsCard = () => {
  return (
    <Card
      style={{
        width: "22%",
        display: "inline-block",
        marginRight: "30px",
        marginBottom: "50px",
      }}
      hoverable
      cover={
        <img
          height={200}
          alt="example"
          src="https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png"
        />
      }
      actions={[<EditOutlined key="edit" />, <DeleteOutlined key="setting" />]}
    >
      <Meta
        style={{fontSize:"12px", textAlign:"center"}}
        // avatar={
        //   <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel" />
        // }
        title="AI Name"
        description={
            <div>
              <p>AI : Pose Craft</p>
              <p>Tarih : 22/03/2023 </p>
              <p>Prompt : Example prompt </p>
            </div>
          }
      />
    </Card>
  );
};

export default CreationsCard;
