import React from 'react'
import { ImProfile } from "react-icons/im";
import { FiLogOut, FiSettings } from "react-icons/fi";
import { MdFolderSpecial } from "react-icons/md";

const ProfileMenu = () => {
  return (
    <div className="homeScreenProfileMenu">
    <div className="containerProfileMenu">
      <button className="buttonProfileMenu">
      </button>
      <div className="menuProfile">
        <a href="/my-creations">
          <ImProfile color="blue" className="menuIcon" /> My Profile
        </a>
        <a href="/podium">
          <MdFolderSpecial color="blue" className="menuIcon" />{" "}
          Specialized
        </a>
        <a href="/products">
          <FiSettings color="blue" className="menuIcon" /> Settings
        </a>
        <a href="/company" style={{ marginBottom: "-2px" }}>
          <FiLogOut color="blue" className="menuIcon" /> Logout
        </a>
      </div>
    </div>
  </div>
  )
}

export default ProfileMenu