import React, { useEffect, useState } from "react";
import bgVideo2 from "../assets/bgVideo2.mp4";
import Base64Image from "../components/Base64Image";
import { post } from "../helper";
import { LuTable2 } from "react-icons/lu";
import { FcSalesPerformance } from "react-icons/fc";
import { FaElementor, FaWarehouse } from "react-icons/fa";
import { BiSupport } from "react-icons/bi";
import {
  MdSocialDistance,
  MdFolderSpecial,
  MdOutlineBurstMode,
} from "react-icons/md";
import { ImProfile } from "react-icons/im";
import { FiSettings, FiLogOut } from "react-icons/fi";
import { Button, Input, InputNumber, Modal, Select, Slider, Tabs } from "antd";
import { useDropzone } from "react-dropzone";
import { DeleteOutlined, GlobalOutlined, HeartOutlined, SaveOutlined } from "@ant-design/icons";
import shapeImg from "../assets/btn-img.jpeg"
import poseImg from "../assets/poseImg.jpeg"
import ProfileMenu from "../components/ProfileMenu";

const ScreenHome = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [base64Image, setBase64Image] = useState("");
  const [promptValue, setPromptValue] = useState();
  const [base64Code, setBase64Code] = useState();
  const [isLoadingPose, setIsLoadingPose] = useState(false);
  const [isLoadingPix, setIsLoadingPix] = useState(false);
  const [id, setId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState();
  const [buttonText, setButtonText] = useState("Shape Shifters");
  const [tabsKey, setTabsKey] = useState("1");
  const [data, setData] = useState();
  const [aiImg, setAiImg] = useState(shapeImg);

  const onDrop = (acceptedFiles) => {
    const arr = [];
    // console.log(acceptedFiles);
    acceptedFiles.map((item) => {
      const file = item;
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result;
          // console.log(base64String)
          arr.push(base64String);
        };
        reader.readAsDataURL(file);
      }
    });
    setTimeout(async () => {
      console.log(arr);
      const obj = {
        images: arr,
      };
      try {
        const result = await post("images/generate/train_lora", obj);
        setId(result.data.id);
        if (result?.status === 200) {
          // setView(true);
          // setIsLoading(false);
        }
      } catch (error) {
        if (error.response.status === 401) alert("Hata meydana geldi");
        setIsLoadingPose(false);
      }
    }, 5000);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
  });

  const onChangeUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
        const base64String = reader.result;
        setBase64Image(base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const onChangeUploadOnModal = (event) => {
    const file = event.target.files;
    console.log(file);
  };

  const handleSubmitPose = async () => {
    setButtonText("Pose Craft");
    setIsLoadingPose(true);
    const obj = {
      image: base64Image,
      prompt: promptValue,
      type: 0,
    };
    try {
      const result = await post("images/generate_pose/reserve", obj);
      setId(result.data.id);
      if (result?.status === 200) {
        // setView(true);
        // setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) alert("Hata meydana geldi");
      setIsLoadingPose(false);
    }
  };

  const handleSubmitPix2Pix = async () => {
    setButtonText("Shape Shifters");
    setIsLoadingPix(true);
    const obj = {
      image: base64Image,
      prompt: promptValue,
      type: 1,
    };
    try {
      const result = await post("images/generate_pose/reserve", obj);
      setId(result.data.id);
      if (result?.status === 200) {
        // setView(true);
        // setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) alert("Hata meydana geldi");
      setIsLoadingPix(false);
    }
  };

  const handleSubmit3 = async () => {
    const obj = {
      prompt: promptValue,
    };
    try {
      const result = await post("images/generate/test_lora", obj);
      setId(result.data.id);
      if (result?.status === 200) {
        // setView(true);
        // setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) alert("Hata meydana geldi");
    }
  };

  const handleSubmit4 = async () => {
    const obj = {
      prompt: promptValue,
    };
    try {
      const result = await post("images/generate/test_textual_inversion", obj);
      setId(result.data.id);
      if (result?.status === 200) {
        // setView(true);
        // setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) alert("Hata meydana geldi");
    }
  };

  const handleSubmit5 = async () => {
    setButtonText("5 AI");
    setIsLoadingPix(true);
    const obj = {
      image: base64Image,
      prompt: promptValue,
      type: 2,
    };
    try {
      const result = await post("images/generate_pose/reserve", obj);
      setId(result.data.id);
      if (result?.status === 200) {
        // setView(true);
        // setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) alert("Hata meydana geldi");
      setIsLoadingPix(false);
    }
  };

  const onChangePrompt = (e) => {
    setPromptValue(e.target.value);
  };

  const deneme = () => {
    setTimeout(async () => {
      if (id) {
        const obj = {
          id: id,
        };
        try {
          const result = await post("images/generate_pose/get_image", obj);
          if (result?.data?.image) {
            setIsLoadingPix(false);
            setIsLoadingPose(false);
            setBase64Code(result?.data?.image?.$binary);
            setId(null);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }, 40000);
  };

  const handleCancel = () => setIsModalOpen(false);

  const handleOk = () => {
    setIsModalOpen(false);
    setButtonText(title);
  };

  useEffect(() => {
    deneme();
  }, [id]);

  useEffect(() => {
    const a = localStorage.getItem("data_table");
    if (a === null) {
      localStorage.setItem(
        "data_table",
        JSON.stringify([
          {
            key: "1",
            name: "My First AI",
            created_date: "01/03/2023",
          },
          {
            key: "2",
            name: "Example AI",
            created_date: "04/23/2023",
          },
          {
            key: "3",
            name: "Last AI",
            created_date: "03/11/2023",
          },
          {
            key: "4",
            name: "Selim AI",
            created_date: "30/05/2021",
          },
          {
            key: "5",
            name: "Ersen AI",
            created_date: "12/12/2023",
          },
          {
            key: "6",
            name: "Serim AI",
            created_date: "28/07/2023",
          },
          {
            key: "7",
            name: "Pose Craft AI",
            created_date: "17/01/2022",
          },
          {
            key: "8",
            name: "Shape Shifters AI",
            created_date: "19/10/2020",
          },
        ])
      );
    } else {
      setData(JSON.parse(localStorage.getItem("data_table")));
    }
  }, []);

  const items = [
    {
      key: "1",
      label: `Train`,
      children: (
        <div className="App">
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <p
              style={{
                border: "1px solid gray",
                borderRadius: "20px",
                padding: "10px",
              }}
            >
              Drag folders or files here
            </p>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: `Test`,
      children: (
        <Select
          showSearch
          placeholder="Select a person"
          optionFilterProp="children"
          // onChange={onChange}
          // onSearch={onSearch}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
        >
          {data?.map((option) => (
            <Select.Option key={option.name}>{option.name}</Select.Option>
          ))}
        </Select>
      ),
    },
  ];

  const onChangeTabs = (key) => {
    setTabsKey(key);
  };

  return (
    <>
      <video
        style={{ width: "100%", height: "100vh", objectFit: "cover" }}
        src={bgVideo2}
        autoPlay
        loop
        muted
      />
      <div className="homeScreenMenu">
        <div className="containerMenu">
          <button className="buttonMenu">AI</button>
          <div className="menu">
            <a href="/my-creations">
              <LuTable2 color="white" className="menuIcon" /> My Creations
            </a>
            <a href="/modals">
              <MdOutlineBurstMode color="white" className="menuIcon" /> Modals
            </a>
            <a href="/podium">
              <FcSalesPerformance color="white" className="menuIcon" /> Podium
            </a>
            <a href="/products">
              <FaElementor color="white" className="menuIcon" /> Portfolio
            </a>
            <a href="/company">
              <FaWarehouse color="white" className="menuIcon" /> Company
            </a>
            <a href="/support">
              <BiSupport color="white" className="menuIcon" /> Support
            </a>
            <a href="/socials">
              <MdSocialDistance color="white" className="menuIcon" /> Socials
            </a>
          </div>
        </div>
      </div>
      <ProfileMenu />
      <div className="homeScreen">
        <div 
          className="viewImg"
          style={selectedImage ? {border:"none"} : {border:"1px solid gray"}}
        >
          {selectedImage && (
            <div>
              <img
                width="100%"
                height={320}
                src={selectedImage}
                alt="Seçili Fotoğraf"
                className="denemeImg"
              />
            </div>
          )}
        </div>
        <div className="container">
          <label htmlFor="file-upload" className="upload-input">
            <input id="file-upload" type="file" onChange={onChangeUpload} />
            <span className="upload-label">Upload Image</span>
          </label>
        </div>
      </div>

      <div className="homeScreen1">
        <div className="labelImg">
          <label>Prompt</label>
        </div>
        <textarea
          type="text"
          className="input-animated br-20"
          onChange={onChangePrompt}
        />
      </div>
      <div className="homeScreen2">
        {/* <div className="wrapper" style={{ marginBottom: "20px" }}>
          <div className="link_wrapper">
            <button disabled={isLoadingPose} onClick={handleSubmitPose}>
              {isLoadingPose ? "Loading..." : "Pose"}
            </button>
            <div className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 268.832 268.832"
              >
                <path d="M265.17 125.577l-80-80c-4.88-4.88-12.796-4.88-17.677 0-4.882 4.882-4.882 12.796 0 17.678l58.66 58.66H12.5c-6.903 0-12.5 5.598-12.5 12.5 0 6.903 5.597 12.5 12.5 12.5h213.654l-58.66 58.662c-4.88 4.882-4.88 12.796 0 17.678 2.44 2.44 5.64 3.66 8.84 3.66s6.398-1.22 8.84-3.66l79.997-80c4.883-4.882 4.883-12.796 0-17.678z" />
              </svg>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="link_wrapper">
            <button disabled={isLoadingPix} onClick={handleSubmitPix2Pix}>
              {isLoadingPose ? "Loading..." : "Pix2Pix"}
            </button>
            <div className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 268.832 268.832"
              >
                <path d="M265.17 125.577l-80-80c-4.88-4.88-12.796-4.88-17.677 0-4.882 4.882-4.882 12.796 0 17.678l58.66 58.66H12.5c-6.903 0-12.5 5.598-12.5 12.5 0 6.903 5.597 12.5 12.5 12.5h213.654l-58.66 58.662c-4.88 4.882-4.88 12.796 0 17.678 2.44 2.44 5.64 3.66 8.84 3.66s6.398-1.22 8.84-3.66l79.997-80c4.883-4.882 4.883-12.796 0-17.678z" />
              </svg>
            </div>
          </div>
        </div> */}
        <div className="containerbtn">
          <button
            style={{ backgroundImage: `url(${aiImg})` }}
            className="ai-button button-with-image"
            onClick={
              buttonText === "Shape Shifters"
                ? handleSubmitPix2Pix
                : buttonText === "Pose Craft"
                ? handleSubmitPose
                : buttonText[0] === "3"
                ? handleSubmit3
                : buttonText[0] === "4"
                ? handleSubmit4
                : handleSubmit5
            }
          >
            {buttonText}
          </button>
          <button
            className="sub-button"
            onClick={() => [setButtonText("Shape Shifters"), setAiImg(shapeImg)]}
            style={{ fontSize: "10px" }}
          >
            Shape Shifters
          </button>
          <button
            className="sub-button"
            onClick={() => [setButtonText("Pose Craft"), setAiImg(poseImg)]}
            style={{ fontSize: "10px" }}
          >
            Pose Craft
          </button>
          <button
            className="sub-button"
            onClick={() => [setIsModalOpen(true), setTitle("3 AI")]}
          >
            3
          </button>
          <button
            className="sub-button"
            onClick={() => [setIsModalOpen(true), setTitle("4 AI")]}
          >
            4
          </button>
          <button className="sub-button" onClick={() => setButtonText("5 AI")}>
            5
          </button>
          {/* <button class="sub-button" onClick={() => [setButtonText("6 AI")]}>
            6
          </button>
          <button class="sub-button" onClick={() => [setButtonText("7 AI")]}>
            7
          </button>
          <button class="sub-button" onClick={() => [setButtonText("8 AI")]}>
            8
          </button>
          <button class="sub-button" onClick={() => [setButtonText("9 AI")]}>
            9
          </button>
          <button class="sub-button" onClick={() => [setButtonText("10 AI")]}>
            10
          </button>
          <button class="sub-button" onClick={() => [setButtonText("11 AI")]}>
            11
          </button>
          <button class="sub-button" onClick={() => [setButtonText("12 AI")]}>
            12
          </button> */}
        </div>
        <Modal
          title={title}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          maskStyle={{
            backdropFilter: "blur(8px)", // Burada blur değerini değiştirerek blurluğun yoğunluğunu ayarlayabilirsiniz.
          }}
          centered
          footer={
            tabsKey === "1" ? (
              [
                // <Button key="back" onClick={handleCancel}>
                //   Save
                // </Button>,
                <Button key="back" onClick={handleOk}>
                  Start
                </Button>,
              ]
            ) : (
              <Button key="back" onClick={handleOk}>
                Save
              </Button>
            )
          }
        >
          {/* <Slider
            defaultValue={80}
            // onChange={(e)=> console.log(e)}
          />
          <Slider
            defaultValue={20}
            // onChange={(e)=> console.log(e)}
          />
          <Slider
            defaultValue={50}
            // onChange={(e)=> console.log(e)}
          />
          <Input style={{margin:"10px 0"}} placeholder="placeholder...">
          </Input>
          <InputNumber min={1} max={10} defaultValue={3} /> */}
          <Tabs defaultActiveKey="1" items={items} onChange={onChangeTabs} />
        </Modal>
      </div>
      <div className="homeScreen3">
        {base64Code && <Base64Image base64Code={base64Code} />}
      </div>
      <div className="homeScreen4">
        <DeleteOutlined style={{color:"gray", fontSize:"30px", marginRight:"20px"}}/>
        <SaveOutlined style={{color:"gray", fontSize:"30px", marginRight:"20px"}} />
        <HeartOutlined style={{color:"gray", fontSize:"30px", marginRight:"20px"}} />
        <GlobalOutlined style={{color:"gray", fontSize:"30px"}} />
      </div>
    </>
  );
};

export default ScreenHome;
