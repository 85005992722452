import {
  ArrowUpOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Card, Carousel, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { BiSupport } from "react-icons/bi";
import { FaElementor, FaWarehouse } from "react-icons/fa";
import { FcSalesPerformance } from "react-icons/fc";
import { FiLogOut, FiSettings } from "react-icons/fi";
import { ImProfile } from "react-icons/im";
import { LuTable2 } from "react-icons/lu";
import {
  MdFolderSpecial,
  MdOutlineBurstMode,
  MdSocialDistance,
} from "react-icons/md";
import a from "../assets/a.jpg";
import b from "../assets/b.jpg";
import c from "../assets/c.jpg";
import user from "../assets/user1.png";
import { useNavigate } from "react-router-dom";
import ProfileMenu from "../components/ProfileMenu";

const HomePage = () => {
    const navigate = useNavigate();

  const images = [a, b, c];

  const [showButton, setShowButton] = useState(false);

  const handleTryModel = () => {
    navigate("/modals");
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 120) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Event listener'ı temizleme
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const leftArrowStyle = {
    position: "absolute",
    top: "50%",
    left: "35px",
    transform: "translateY(-50%)",
    fontSize: "30px",
    color: "white",
    cursor: "pointer",
  };

  const rightArrowStyle = {
    position: "absolute",
    top: "50%",
    right: "35px",
    transform: "translateY(-50%)",
    fontSize: "30px",
    color: "white",
    cursor: "pointer",
  };

  return (
    <div style={{ backgroundColor: "black", height: "160vh", width: "100%" }}>
      <div className="homeScreenMenu">
        <div className="containerMenu">
          <button className="buttonMenu">AI</button>
          <div className="menu">
            <a href="/my-creations">
              <LuTable2 color="white" className="menuIcon" /> My Creations
            </a>
            <a href="/modals">
              <MdOutlineBurstMode color="white" className="menuIcon" /> Modals
            </a>
            <a href="/podium">
              <FcSalesPerformance color="white" className="menuIcon" /> Podium
            </a>
            <a href="/products">
              <FaElementor color="white" className="menuIcon" /> Portfolio
            </a>
            <a href="/company">
              <FaWarehouse color="white" className="menuIcon" /> Company
            </a>
            <a href="/support">
              <BiSupport color="white" className="menuIcon" /> Support
            </a>
            <a href="/socials">
              <MdSocialDistance color="white" className="menuIcon" /> Socials
            </a>
          </div>
        </div>
      </div>
      <ProfileMenu />
      <div style={{ textAlign: "center", paddingTop: "4vh" }}>
        {showButton ? (
          <Button
            className="vertical-button"
            onClick={handleTryModel}
          >
            <span className="vertical-text">Try your model</span>
          </Button>
        ) : (<Button
            className="tryModelBtn"
            onClick={handleTryModel}
          >
            Try Your Model
          </Button>) }
      </div>
      <div class="centered-div-container">
        <div class="centered-div">
          <Row gutter={24}>
            <Col span={8}>
              <Card
                style={{
                  height: 150,
                  backgroundColor: "rgba(19, 19, 19, 0.8)",
                  border: "none",
                  position: "relative",
                  lineHeight: "30px",
                  fontWeight: 300,
                }}
              >
                <h3
                  style={{
                    textAlign: "center",
                    color: "#FF7844",
                    letterSpacing: 5,
                    fontWeight: 300,
                    marginTop: "-10px",
                  }}
                >
                  Trend Colors
                </h3>
                <p style={{ textAlign: "center" }}>
                  <span style={{ color: "white", marginRight: "8px" }}>
                    1. Red
                  </span>
                  <ArrowUpOutlined style={{ color: "green" }} />
                </p>
                <p style={{ textAlign: "center" }}>
                  <span style={{ color: "white", marginRight: "8px" }}>
                    2. Blue
                  </span>
                  <ArrowUpOutlined style={{ color: "green" }} />
                </p>
                <p style={{ textAlign: "center" }}>
                  <span style={{ color: "white", marginRight: "8px" }}>
                    3. Green
                  </span>
                  <ArrowUpOutlined style={{ color: "red" }} />
                </p>
                <div
                  style={{
                    position: "absolute",
                    right: "8px",
                    bottom: "8px",
                  }}
                >
                  <RightOutlined style={{ color: "white", fontSize: "20px" }} />
                </div>
              </Card>
            </Col>
            <Col span={8}>
              <Card
                style={{
                  height: 150,
                  backgroundColor: "rgba(19, 19, 19, 0.8)",
                  border: "none",
                  position: "relative",
                  lineHeight: "30px",
                  letterSpacing: 2,
                  fontWeight: 300,
                }}
              >
                <h3
                  style={{
                    textAlign: "center",
                    color: "#FF7844",
                    letterSpacing: 5,
                    fontWeight: 300,
                    marginTop: "-10px",
                  }}
                >
                  Trend Selling Products
                </h3>
                <p style={{ textAlign: "center" }}>
                  <span style={{ color: "white", marginRight: "18px" }}>
                    1. T-Shirt
                  </span>
                  <span style={{ color: "green" }}>54%</span>
                </p>
                <p style={{ textAlign: "center" }}>
                  <span style={{ color: "white", marginRight: "18px" }}>
                    1. Trousers
                  </span>
                  <span style={{ color: "green" }}>68%</span>
                </p>
                <p style={{ textAlign: "center" }}>
                  <span style={{ color: "white", marginRight: "18px" }}>
                    1. Shoes
                  </span>
                  <span style={{ color: "green" }}>45%</span>
                </p>
                <div
                  style={{
                    position: "absolute",
                    right: "8px",
                    bottom: "8px",
                  }}
                >
                  <RightOutlined style={{ color: "white", fontSize: "20px" }} />
                </div>
              </Card>
            </Col>
            <Col span={8}>
              <Card
                style={{
                  height: 150,
                  backgroundColor: "rgba(19, 19, 19, 0.8)",
                  border: "none",
                  position: "relative",
                  lineHeight: "25px",
                  fontWeight: 300,
                }}
              >
                <h3
                  style={{
                    textAlign: "center",
                    color: "#FF7844",
                    letterSpacing: 5,
                    fontWeight: 300,
                    marginTop: "-10px",
                  }}
                >
                  Most Review Accounts
                </h3>
                <p
                  style={{
                    textAlign: "center",
                    margin: "5px",
                    letterSpacing: 3,
                  }}
                >
                  <span style={{ color: "white", marginRight: "8px" }}>
                    3. Ersen Taşkın
                  </span>
                  <button type="button" className="fallowButton">
                    <span className="icon">+ </span>
                    Fallow
                  </button>
                </p>
                <p
                  style={{
                    textAlign: "center",
                    margin: "5px",
                    letterSpacing: 3,
                  }}
                >
                  <span style={{ color: "white", marginRight: "8px" }}>
                    3. Ersen Taşkın
                  </span>
                  <button type="button" class="fallowButton">
                    <span class="icon">+ </span>
                    Fallow
                  </button>
                </p>
                <p
                  style={{
                    textAlign: "center",
                    margin: "5px",
                    letterSpacing: 3,
                  }}
                >
                  <span style={{ color: "white", marginRight: "8px" }}>
                    3. Ersen Taşkın
                  </span>
                  <button type="button" class="fallowButton">
                    <span class="icon">+ </span>
                    Fallow
                  </button>
                </p>
                <div
                  style={{
                    position: "absolute",
                    right: "8px",
                    bottom: "8px",
                  }}
                >
                  <RightOutlined style={{ color: "white", fontSize: "20px" }} />
                </div>
              </Card>
            </Col>
          </Row>
          <Row gutter={24} style={{ marginTop: "40px" }}>
            <Col span={24}>
              <Carousel autoplay dots={false}>
                {images.map((image, index) => (
                  <div key={index}>
                    <img
                      src={image}
                      alt={`Image ${index + 1}`}
                      style={{ width: "100%", height: "300px" }}
                    />
                  </div>
                ))}
              </Carousel>
              <LeftOutlined style={leftArrowStyle} />
              <RightOutlined style={rightArrowStyle} />
            </Col>
          </Row>
          <Row
            gutter={24}
            style={{
              color: "white",
              fontWeight: 500,
              lineHeight: 1.2,
              marginTop: "40px",
            }}
          >
            <Col>
              <p
                style={{ fontSize: "25px", letterSpacing: 10, fontWeight: 400 }}
              >
                Trending on AI Models
              </p>
            </Col>
          </Row>
          <Row gutter={24} style={{ color: "white", marginTop: "10px" }}>
            <Col>
              <p style={{ fontWeight: 300 }}>
                Discover the latest fashion news and see what's happening on
                social media & what influencers are doing.
              </p>
            </Col>
          </Row>
          {images.map((item) => (
            <Row gutter={24} style={{ color: "white", marginTop: "30px" }}>
              <Col span={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "white",
                  }}
                >
                  <Avatar size={64} src={user} />
                  <h3 style={{ marginLeft: "16px", fontWeight: 400 }}>
                    Tiger Woods
                  </h3>
                </div>
                <p
                  style={{
                    marginTop: "10px",
                    letterSpacing: 3,
                    fontWeight: 200,
                  }}
                >
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Suscipit ab quis soluta ratione expedita dolore qui iusto
                  eveniet consequuntur harum, mollitia ad.
                </p>
              </Col>
              <Col span={12} push={1}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "white",
                  }}
                >
                  <Avatar size={64} src={user} />
                  <h3 style={{ marginLeft: "16px", fontWeight: 400 }}>
                    Tiger Woods
                  </h3>
                </div>
                <p
                  style={{
                    marginTop: "10px",
                    letterSpacing: 3,
                    fontWeight: 200,
                  }}
                >
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Suscipit ab quis soluta ratione expedita dolore qui iusto
                  eveniet consequuntur harum, mollitia ad.
                </p>
              </Col>
            </Row>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
