import React, { useEffect, useState } from "react";
import bgVideo2 from "../assets/bgVideo2.mp4";
import Base64Image from "../components/Base64Image";
import { post } from "../helper";
import { LuTable2 } from "react-icons/lu";
import { FcSalesPerformance } from "react-icons/fc";
import { FaElementor, FaWarehouse } from "react-icons/fa";
import { BiSupport } from "react-icons/bi";
import { MdSocialDistance, MdFolderSpecial } from "react-icons/md";
import { ImProfile } from "react-icons/im";
import { FiSettings, FiLogOut } from "react-icons/fi";
import { Input, InputNumber, Modal, Slider } from "antd";

const ScreenHomeMobile = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [base64Image, setBase64Image] = useState("");
  const [promptValue, setPromptValue] = useState();
  const [base64Code, setBase64Code] = useState();
  const [isLoadingPose, setIsLoadingPose] = useState(false);
  const [isLoadingPix, setIsLoadingPix] = useState(false);
  const [id, setId] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title,setTitle] = useState();
  const [buttonText,setButtonText] = useState("Shape Shifters");

  const onChangeUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
        const base64String = reader.result;
        setBase64Image(base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmitPose = async () => {
    setButtonText("Pose Craft")
    setIsLoadingPose(true);
    const obj = {
      image: base64Image,
      prompt: promptValue,
      type: 0,
    };
    try {
      const result = await post("images/generate_pose/reserve", obj);
      setId(result.data.id);
      if (result?.status === 200) {
        // setView(true);
        // setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) alert("Hata meydana geldi");
      setIsLoadingPose(false);
    }
  };

  const handleSubmitPix2Pix = async () => {
    setButtonText("Shape Shifters")
    setIsLoadingPix(true);
    const obj = {
      image: base64Image,
      prompt: promptValue,
      type: 1,
    };
    try {
      const result = await post("images/generate_pose/reserve", obj);
      setId(result.data.id);
      if (result?.status === 200) {
        // setView(true);
        // setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) alert("Hata meydana geldi");
      setIsLoadingPix(false);
    }
  };

  const handleSubmit5 = async () => {
    setButtonText("5 AI")
    setIsLoadingPix(true);
    const obj = {
      image: base64Image,
      prompt: promptValue,
      type: 2,
    };
    try {
      const result = await post("images/generate_pose/reserve", obj);
      setId(result.data.id);
      if (result?.status === 200) {
        // setView(true);
        // setIsLoading(false);
      }
    } catch (error) {
      if (error.response.status === 401) alert("Hata meydana geldi");
      setIsLoadingPix(false);
    }
  };

  const onChangePrompt = (e) => {
    setPromptValue(e.target.value);
  };

  const deneme = () => {
    setTimeout(async () => {
      if (id) {
        const obj = {
          id: id,
        };
        try {
          const result = await post("images/generate_pose/get_image", obj);
          if (result?.data?.image) {
            setIsLoadingPix(false);
            setIsLoadingPose(false);
            setBase64Code(result?.data?.image?.$binary);
            setId(null);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }, 50000);
  };

  const handleCancel = () => setIsModalOpen(false)

  const handleOk = () => {
    setIsModalOpen(false)
    setButtonText(title)
  }

  useEffect(() => {
    deneme();
  }, [id]);

  return (
    <>
      <video
        style={{ width: "100%", height: "220vh", objectFit: "cover" }}
        src={bgVideo2}
        autoPlay
        loop
        muted
      />
      <div className="homeScreenMenu">
        <div className="containerMenu">
          <button className="buttonMenu">AI</button>
          <div className="menu">
            <a href="/my-creations">
              <LuTable2 color="white" className="menuIcon" /> My Creations
            </a>
            <a href="/podium">
              <FcSalesPerformance color="white" className="menuIcon" /> Market
              Place
            </a>
            <a href="/products">
              <FaElementor color="white" className="menuIcon" /> Products
            </a>
            <a href="/company">
              <FaWarehouse color="white" className="menuIcon" /> Company
            </a>
            <a href="/support">
              <BiSupport color="white" className="menuIcon" /> Support
            </a>
            <a href="/socials">
              <MdSocialDistance color="white" className="menuIcon" /> Socials
            </a>
          </div>
        </div>
      </div>
      <div className="homeScreenMobile">
        <div className="viewImg">
          {selectedImage && (
            <div>
              <img
                width="100%"
                height={320}
                src={selectedImage}
                alt="Seçili Fotoğraf"
                className="denemeImg"
              />
            </div>
          )}
        </div>
        <div className="containerMobile">
          <label htmlFor="file-upload" className="upload-input">
            <input id="file-upload" type="file" onChange={onChangeUpload} />
            <span className="upload-labelMobile">Upload Image</span>
          </label>
        </div>
      </div>

      <div className="homeScreen1Mobile">
        <div className="labelImg">
          <label>Prompt</label>
        </div>
        <textarea
          type="text"
          className="input-animated br-20"
          onChange={onChangePrompt}
        />
      </div>

      <div className="homeScreen2Mobile">
        {/* <div className="wrapper" style={{ marginBottom: "20px" }}>
          <div className="link_wrapper">
            <button disabled={isLoadingPose} onClick={handleSubmitPose}>
              {isLoadingPose ? "Loading..." : "Pose"}
            </button>
            <div className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 268.832 268.832"
              >
                <path d="M265.17 125.577l-80-80c-4.88-4.88-12.796-4.88-17.677 0-4.882 4.882-4.882 12.796 0 17.678l58.66 58.66H12.5c-6.903 0-12.5 5.598-12.5 12.5 0 6.903 5.597 12.5 12.5 12.5h213.654l-58.66 58.662c-4.88 4.882-4.88 12.796 0 17.678 2.44 2.44 5.64 3.66 8.84 3.66s6.398-1.22 8.84-3.66l79.997-80c4.883-4.882 4.883-12.796 0-17.678z" />
              </svg>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="link_wrapper">
            <button disabled={isLoadingPix} onClick={handleSubmitPix2Pix}>
              {isLoadingPose ? "Loading..." : "Pix2Pix"}
            </button>
            <div className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 268.832 268.832"
              >
                <path d="M265.17 125.577l-80-80c-4.88-4.88-12.796-4.88-17.677 0-4.882 4.882-4.882 12.796 0 17.678l58.66 58.66H12.5c-6.903 0-12.5 5.598-12.5 12.5 0 6.903 5.597 12.5 12.5 12.5h213.654l-58.66 58.662c-4.88 4.882-4.88 12.796 0 17.678 2.44 2.44 5.64 3.66 8.84 3.66s6.398-1.22 8.84-3.66l79.997-80c4.883-4.882 4.883-12.796 0-17.678z" />
              </svg>
            </div>
          </div>
        </div> */}
        <div class="containerbtn">
          <button class="ai-button">{buttonText}</button>
          <button class="sub-button" onClick={handleSubmitPix2Pix} style={{fontSize:"10px"}}>
          Shape Shifters
          </button>
          <button class="sub-button" onClick={handleSubmitPose} style={{fontSize:"10px"}}>
            Pose Craft
          </button>
          <button
            class="sub-button"
            onClick={() => [setIsModalOpen(true), setTitle("3 AI")]}
          >
            3
          </button>
          <button
            class="sub-button"
            onClick={() => [setIsModalOpen(true), setTitle("4 AI")]}
          >
            4
          </button>
          <button class="sub-button" onClick={handleSubmit5}>
            5
          </button>
          {/* <button class="sub-button" onClick={() => [setButtonText("6 AI")]}>
            6
          </button>
          <button class="sub-button" onClick={() => [setButtonText("7 AI")]}>
            7
          </button>
          <button class="sub-button" onClick={() => [setButtonText("8 AI")]}>
            8
          </button>
          <button class="sub-button" onClick={() => [setButtonText("9 AI")]}>
            9
          </button>
          <button class="sub-button" onClick={() => [setButtonText("10 AI")]}>
            10
          </button>
          <button class="sub-button" onClick={() => [setButtonText("11 AI")]}>
            11
          </button>
          <button class="sub-button" onClick={() => [setButtonText("12 AI")]}>
            12
          </button> */}
        </div>
        <Modal
          title={title}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          maskStyle={{
            backdropFilter: "blur(8px)", // Burada blur değerini değiştirerek blurluğun yoğunluğunu ayarlayabilirsiniz.
          }}
          centered
          width="50%"
        >
          <Slider
            defaultValue={80}
            // onChange={(e)=> console.log(e)}
          />
          <Slider
            defaultValue={20}
            // onChange={(e)=> console.log(e)}
          />
          <Slider
            defaultValue={50}
            // onChange={(e)=> console.log(e)}
          />
          <Input style={{margin:"10px 0"}} placeholder="placeholder...">
          </Input>
          <InputNumber min={1} max={10} defaultValue={3} />
        </Modal>
      </div>
      <div className="homeScreen3Mobile">
        {base64Code && <Base64Image base64Code={base64Code} />}
      </div>
      <div className="homeScreenProfileMenuMobile">
        <div className="containerProfileMenuMobile">
          <button className="buttonProfileMenuMobile">
          </button>
          <div className="menuProfileMobile">
            <a href="#">
              <ImProfile color="blue" className="menuIcon" /> My Profile
            </a>
            <a href="#">
              <MdFolderSpecial color="blue" className="menuIcon" /> Specialized
            </a>
            <a href="#">
              <FiSettings color="blue" className="menuIcon" /> Settings
            </a>
            <a href="#" style={{ marginBottom: "-2px" }}>
              <FiLogOut color="blue" className="menuIcon" /> Logout
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScreenHomeMobile;
