import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginPost } from "../helper";
import { authLogin } from "../services";

const LoginScreen = () => {
  const navigate = useNavigate();

  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const values = {
      email: userName,
      password: password,
    };
    try {
      const result = await loginPost(authLogin, values);
      localStorage.setItem(
        "accessToken",
        JSON.stringify(result.data.result.access_token).toString()
      );
      if (result.status === 200) navigate("/home");
    } catch (error) {
      if (error.response.status === 401) alert("Şifre Yanlış");
    }
    navigate("/home");
  };

  const onChangeUserName = (e) => setUserName(e.target.value);

  const onChangePassword = (e) => setPassword(e.target.value);

  return (
    <>
      <div className="loginScreenBg"></div>
      <div className="loginScreen">
        <div className="background">
          <div className="shape"></div>
          <div className="shape"></div>
        </div>
        <form>
          <h3>Login Here</h3>
          <label>Username</label>
          <input
            type="text"
            placeholder="Email or Phone"
            id="username"
            onChange={onChangeUserName}
          />
          <label>Password</label>
          <input
            type="password"
            placeholder="Password"
            id="password"
            onChange={onChangePassword}
          />
          <button type="submit" onClick={handleSubmit}>
            Log In
          </button>
          <div >
            <button style={{display:"inline-block", width:"40%", marginRight:"20%", fontSize:"12px"}}>Forgot Password</button>
            <button style={{display:"inline-block", width:"40%",  fontSize:"12px"}}>Register</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default LoginScreen;
