import React from "react";

class Base64Image extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: null,
    };
  }

  componentDidMount() {
    const { base64Code } = this.props;
    console.log(base64Code);
    this.convertBase64ToImage(base64Code);
  }

  convertBase64ToImage(base64Code) {
    const img = new Image();
    const decodedText = atob(base64Code);
    img.src = `data:image/png;base64,${decodedText}`;
    img.onload = () => {
      this.setState({ imageUrl: img.src });
    };
  }

  render() {
    const { imageUrl } = this.state;
    return (
      <>
        <div>
          {imageUrl && (
            <img
              style={{ borderRadius: "10px" }}
              width="100%"
              height="587vh"
              src={imageUrl}
              alt="Converted"
            />
          )}
        </div>
      </>
    );
  }
}

export default Base64Image;
