import React from "react";
import { Routes, Route } from "react-router-dom";
import "./assets/App.css";
import Home from "./pages/Home";
import LoginScreen from "./pages/LoginScreen";
import MyCreations from "./pages/MyCreations";
import Products from "./pages/Products";
import Company from "./pages/Company";
import Support from "./pages/Support";
import Socials from "./pages/Socials";
import MarketPlace from "./pages/MarketPlace";
import Modals from "./pages/Modals";
import HomePage from "./pages/HomePage";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginScreen />} />
      <Route path="/home" element={<HomePage />} />
      <Route path="/my-creations" element={<MyCreations />} />
      <Route path="/modals" element={<Home />} />
      <Route path="/podium" element={<MarketPlace />} />
      <Route path="/products" element={<Products />} />
      <Route path="/company" element={<Company />} />
      <Route path="/support" element={<Support />} />
      <Route path="/socials" element={<Socials />} />
    </Routes>
  );
};
export default App;
